html{
  height:100vh;
}

body{
  height:100vh;
  background:url('/img/website/blue_gradient.jpg');
  background-size:cover;
}
.login-wrapper{
  height:100%;
  .valign{
    min-width:250px;
    max-width:400px;
    min-width:20%;
  }
  .card-panel{
    margin-bottom: 30px;
  }
  .card {
    padding: 10px;
  }
}
.card-action.block-buttons{
  padding:0;
  a{
    display:inline-block;
    padding:20px;
  }
}

.card .card-action a.right{
  margin-right:0 !important;
}

ul.bullets, .bullets li{
  list-style-type: initial;
  margin:0 0 0 .5em;
}